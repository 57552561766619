import React from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import PageTitle from "../components/page-title"
import { StaticImage } from "gatsby-plugin-image"
import HeroBanner from "../components/home/heroBanner/HeroBanner"
import { Container } from "reactstrap"

let PSEA = () => {

    return (
        <>
            <SEO
                title="BIM & CAD Training Center | Ace Industrial Academy"
                description="Ace Industrial Academy is a leading training center in Singapore for IT courses, BIM courses, 3D CAD courses, 4D courses, software courses, building information modeling courses and more."
            />
            {/* <PageTitle title="BIM & CAD Training Center" /> */}
            {/* <HeroBanner /> */}
            <Container className="pt-4">
                {/* <div className="text-center mb-2">
                    <StaticImage
                        src="../assets/images/hero_image.png"
                        alt="Acra_icon"
                    />
                </div> */}

                <h2 class="trainingTitle">SkillsFuture Credit</h2>
                <p class="trainingContent">
                    <br />
                    All Singaporeans aged 25 years and above received an opening credit of $500. From 1 May 2024, Singaporeans aged 40 years and above will receive a <a href="https://www.skillsfuture.gov.sg/initiatives/mid-career/credit">SkillsFuture Credit</a> (Mid-Career) top-up to pursue a substantive skills reboot.
                    <br /><br />
                    {/* Below are the options for SkillsFuture funding:
                    <br /><br /> */}
                </p>
                <h5 class="trainingContent">List of courses that accept SFC Payment: <a href="https://www.aia.edu.sg/courses-skillsfuture/">SkillsFuture Approved Courses</a> </h5>
            </Container>

            <Container className="pt-4">
                <h4 className="trainingContent mt-3">SkillsFuture Credit Claim Procedure:</h4>
            </Container>
            <Container className="pt-4">
                <p>
                    1. Upon successful registration, ACE INDUSTRIAL ACADEMY will provide participants with a tax invoice.<br /><br />
                    2. Access the MySkillsFuture portal: <a href=" https://www.myskillsfuture.gov.sg/"> https://www.myskillsfuture.gov.sg/</a> using your Singpass App OR Singpass ID and password to log in.<br /><br />
                    3. Upon successful login, click ‘submit a claim’.<br /><br />
                </p>
                <br />
                <div className="text-center mb-2">
                    <StaticImage
                        src="../assets/images/SFC1.png"
                        alt="Acra_icon"
                    //width="1000" height="320"
                    />
                </div>
                <br />
                <p>
                    4. Search course by ‘course name/ref no’ OR by ‘training provider’.<br /><br />
                </p>
                <br />
                <div className="text-center mb-2">
                    <StaticImage
                        src="../assets/images/SFC2.png"
                        alt="Acra_icon"
                    //width="1000" height="180"
                    />
                </div>
                <br />
                <p>
                    5. Upon choosing the training course, select the course run.<br /><br />
                </p>
                <br />
                <div className="text-center mb-2">
                    <StaticImage
                        src="../assets/images/SFC3.png"
                        alt="Acra_icon"
                    //width="900" height="400"
                    />
                </div>
                <br />
                <p>
                    6. Do ensure that the training admin has provided you with the tax invoice for the payment details.<br /><br />
                </p>
                <br />
                <div className="text-center mb-2">
                    <StaticImage
                        src="../assets/images/SFC4.png"
                        alt="Acra_icon"
                    //width="800" height="500"
                    />
                </div>
                <br />
                <p>
                    7. Ensure that the details submitted is correct & notify the ACE INDUSTRIAL ACADEMY upon successful claim submission.<br /><br />
                </p>
                <br />
                <div className="text-center mb-2">
                    <StaticImage
                        src="../assets/images/SFC5.png"
                        alt="Acra_icon"
                    //width="900" height="400"
                    />
                </div>
                <br />

            </Container>
        </>
    )
}

export default PSEA
